import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';
import { SelectedSubcontractorService } from '../../services/subcontractor/selectedSubcontractor.service';
import { SubcontractorModel } from '../../_models/subcontractors.model';
import { Observable, of } from 'rxjs';
import { HostListener } from '@angular/core';
import { StateComponent } from '../../components/state/state.component';
import { TagsComponent } from '../../components/tags/tags.component';
import { CompanyTypeChipComponent } from '../../components/company-type-chip/company-type-chip.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  standalone: true,
  imports: [CommonModule, StateComponent, TagsComponent, CompanyTypeChipComponent]
})
export class DetailComponent implements OnInit {
  subcontractorDetail$: Observable<SubcontractorModel | null> = of(null);
  subcontractorTags: string[] = [];
  subcontractorState: boolean = false;
  subcontractorLastUpdate: Date = new Date();

  constructor(
    private selectedSubcontractorService: SelectedSubcontractorService,
    private location: Location
  ) {}

  ngOnInit() {
    const { companyId, subcontractorId} = this.selectedSubcontractorService.getSelectedIds();
    this.subcontractorTags = this.selectedSubcontractorService.getSelectedSubcontractorInfo().subcontractorTags;
    this.subcontractorState = this.selectedSubcontractorService.getSelectedSubcontractorInfo().subcontractorState;
    this.subcontractorLastUpdate = this.selectedSubcontractorService.getSelectedSubcontractorInfo().subcontractorLastUpdate;
    if (companyId && subcontractorId) {
      this.subcontractorDetail$ = this.selectedSubcontractorService.getSubcontractorDetail();
    } else {
      console.error('Company ID or Subcontractor ID is missing');
    }
  }

  goBack(): void {
    this.location.back();
  }

  stickyheaderFixed: boolean = false;

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 1) {
      this.stickyheaderFixed = true;
    } 
    else 
    {
      this.stickyheaderFixed = false;
    }
  }


}
