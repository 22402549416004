import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './_views/page-not-found/page-not-found.component';
import { DetailComponent } from './_views/detail/detail.component';

export const routes: Routes = [
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    {
        path: 'pages', children: [
            {
                path: '',
                redirectTo: 'subcontractor',
                pathMatch: 'full'
            },
            {
                path: 'subcontractor',
                loadChildren: () => import('./_views/subcontractor/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'detail',
                component: DetailComponent
            }
        ]
    },
    { path: '**', component: PageNotFoundComponent }
];
