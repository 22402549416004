import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-company-type-chip',
  standalone: true,
  imports: [],
  templateUrl: './company-type-chip.component.html',
  styleUrl: './company-type-chip.component.scss'
})
export class CompanyTypeChipComponent {
  @Input() companyType: string = 'Subcontratista';

}
