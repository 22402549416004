<div style="height: 2000px;">
<div class="block-container" (scroll)="onScroll()" [ngClass]="stickyheaderFixed?'fixed':'not-fixed'">
  <div class="top-bar"></div>
    <div class="content-row">
      <div class="left-tags">
        <nav class="breadcrumb">
          <a href="#"><img src="images/home.png"></a>
          <a (click)="goBack()"> / Mis Subcontratistas /</a>
          <span>
              {{ (subcontractorDetail$ | async)?.company_name || 'Detalle' }}
          </span>
        </nav>
        <app-company-type-chip></app-company-type-chip>
        <app-state [state]="subcontractorState"></app-state>
      </div>
      <div class="right-tags">
        <span class="last-update">Última actualización: {{subcontractorLastUpdate | date:'yyyy/MM/dd HH:mm:ss'}}</span>
        <app-tags [tags]="subcontractorTags"></app-tags>
      </div>
    </div>   
</div>
</div>